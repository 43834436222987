import React from 'react';
import { useTranslation } from 'react-i18next';
import FullWidthImage from './FullWidthImage';
import ImageSeparator from './ImageSeparator';
import SplitContent from './SplitContent';
import CharacterCarousel from './CharacterCarousel';
import EmailForm from './EmailForm';

function Home() {
  const { t } = useTranslation();

  return (
    <section className="bg-dark-red flex flex-col items-center text-white" id="top">
      {/* <div className="h-16"></div> */}

      {/* Full Width Image */}
      <FullWidthImage 
        src="/images/_0018_illustrazione_sfondo_TOP.png" 
        alt="Background" 
        height="400px" 
        overlaySrc="/images/_0002_SANTHERYA_LOGO.png"  
        overlayAlt="Overlay" 
      />

<div id="newsletter"></div>
      {/* Image Separator */}
      <ImageSeparator src="images/_0003_divisore_complesso.png" alt="Separator"/>

      <div
  className="bg-black w-full h-auto flex flex-col justify-center items-center text-white px-4 py-8"
  style={{
    backgroundImage: `url('images/_0020_banner_mappa_1.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}  
>
  <div className="text-center max-w-4xl">
    {/* Titolo */}
    <h2 className="text-2xl md:text-3xl font-bold mb-4">
      {t('background_section_title')}
    </h2>
    {/* Testo */}
    <p className="text-base md:text-lg text-left mx-auto">
      {t('background_section_text')}
    </p>
  </div>

  {/* Formulario Email */}
  <div className="w-full sm:w-3/4 md:w-1/2 mt-8">
    <EmailForm />
  </div>
</div>

      <ImageSeparator src="images/_0004_divisore_unico.png" alt="Separator" />
      <div className='h-16' id="game"></div>
      {/* Split Content */}
      <SplitContent
        imageSide="left"
        imageSrc="images/_0009_B1.png"
        bgImageSrc="images/_0010_B2.png"
        titleKey="split_content_title_1"
        bodyKey="split_content_body_1"
        height="100%"
        t={t}
      />
      
      <div className='h-8'></div>
 {/* Split Content */}
 <SplitContent
        imageSide="right"
        imageSrc="images/_0012_B4.png"
        bgImageSrc="images/_0011_B3.png"
        titleKey="split_content_title_2"
        bodyKey="split_content_body_2"
        height="100%"
        t={t}
      />
      <div className='h-8'></div>
      <SplitContent
        imageSide="left"
        imageSrc="images/B9.png"
        bgImageSrc="images/B10.png"
        titleKey="split_content_title_3"
        bodyKey="split_content_body_3"
        height="100%"
        t={t}
      />
      <div className='h-8'></div>
     
       <SplitContent
        imageSide="right"
        imageSrc="images/_0016_B8.png"
        bgImageSrc="images/_0015_B7.png"
        titleKey="split_content_title_4"
        bodyKey="split_content_body_4"
        height="100%"
        t={t}
      />
      <div className='h-8'></div>
 {/* Split Content */}
 <SplitContent
        imageSide="left"
        imageSrc="images/_0013_B5.png"
        bgImageSrc="images/_0014_B6.png"
        titleKey="split_content_title_5"
        bodyKey="split_content_body_5"
        height="100%"
        t={t}
      />
      <div className='h-8' id="story"></div>
      <ImageSeparator src="images/_0005_divisore_semplice.png" alt="Separator" />
      
      <div
        className="bg-black w-full h-auto flex flex-col justify-center items-center text-white"
        style={{
          backgroundImage: `url('images/_0021_Sfondo_rosso_pergamena.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='h-12'></div>
        <h2 className="text-3xl font-bold mb-4">{t('background_section_title_2')}</h2>
        <p className="text-base text-left px-4 max-w-4xl">{t('background_section_text_2')}</p>
        
        <div className='h-12'></div>
        <img
          src="images/_0006_banner_mappa_2.png"
          alt="map"
          className="w-full object-cover"
        />
        <div className='h-8'></div>
        <div className="w-11/12 grid grid-cols-1 md:grid-cols-3 gap-16 mx-auto">
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold m-2 p-2">{t('column_1_title')}</h3>
            <p className="text-base">{t('column_1_body')}</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold m-2 p-2">{t('column_2_title')}</h3>
            <p className="text-base">{t('column_2_body')}</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold m-2 p-2">{t('column_3_title')}</h3>
            <p className="text-base">{t('column_3_body')}</p>
          </div>
        </div>
        <div className='h-16'></div>
      </div>
<ImageSeparator src="images/_0005_divisore_semplice.png" alt="Separator" />
<div
        className="bg-black w-full h-auto flex flex-col justify-center items-center text-white"
        style={{
          backgroundImage: `url('images/_0022_Sfondo_personaggi.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='h-12'></div>
        <CharacterCarousel />
      <div className='h-8'></div>
      </div>
      <ImageSeparator src="images/_0003_divisore_complesso.png" alt="Separator" />
      
    </section>
  );
}

export default Home;
