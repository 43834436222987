// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';

// function EmailForm() {

//     const { i18n } = useTranslation();
//   const { t } = useTranslation();
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
 
//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     setError('');
//     setSuccess('');
//   };

//   const handleTermsChange = (e) => {
//     setTermsAccepted(e.target.checked);
//     setError('');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Email validation
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailPattern.test(email)) {
//       setError(t('invalid_email'));
//       return;
//     }
    
//     if (!termsAccepted) {
//       setError('You must accept the terms and conditions');
//       return;
//     }
   
//     const mailEndpoint = `https://`;

//     try {
//       const response = await fetch(mailEndpoint, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email_address: email,
//           language: i18n.language,          
//           status: 'subscribed',
//         }),
//       });

//       if (response.ok) {
//         setSuccess(t('subscription_success'));
//         setEmail('');
//       } else {
//         setError(t('subscription_failure'));
//       }
//     } catch (error) {
//       setError(t('subscription_failure'));
//     }
//   };
//   return (
//    <div>
// <div className="text-white p-6 bg-black  rounded-lg border-4 border-white shadow-md text-center w-full">
//         <h2 className="text-3xl font-bold mb-8 mt-4">{t('register_message')}</h2>
//         <form className="flex flex-col mb-8 md:flex-row items-center" onSubmit={handleSubmit}>
//           <input
//             type="email"
//             value={email}
//             onChange={handleEmailChange}
//             placeholder={t('email_placeholder')}
//             className="px-4 py-2 border rounded-t-lg md:rounded-l-lg md:rounded-t-none w-full mb-4 md:mb-0 md:mr-2"
//             required
//           />
//           <div className="flex items-center mb-4 md:mb-0 md:mr-2">
//             {/* <input
//               type="checkbox"
//               checked={termsAccepted}
//               onChange={handleTermsChange}
//               className="mr-2"
//               required
//             />
//             <label>{t('terms_read')}</label> */}
//           </div>
//           <button type="submit" className="border-4 border-white text-white px-4 py-2 rounded-b-lg md:rounded-r-lg md:rounded-b-none w-full md:w-auto">
//             {t('register_button')}
//           </button>
//         </form>
//         <div className="flex items-center m-4 gap-8 mb-4 md:mb-0 md:mr-2">
//             {/* <a href="/#" className="text-blue-500 mb-1" target="_blank" rel="noopener noreferrer">{t('Privacy Policy')}</a>
//             <a href="/#" className="text-blue-500" target="_blank" rel="noopener noreferrer">{t('terms_and_conditions')}</a> */}
//           </div>
//         {error && <p className="text-red-500">{error}</p>}
//         {success && <p className="text-green-500">{success}</p>}
//       </div>

//    </div>
//   );
// }

// export default EmailForm;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function EmailForm() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
    setSuccess('');
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('invalid_email'));
      return;
    }

    // if (!termsAccepted) {
    //   setError('You must accept the terms and conditions');
    //   return;
    // }

    const mailEndpoint = `https://api.santherya.com/subscribe`;
    
    try {
      const response = await fetch(mailEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          language: i18n.language,
        }),
      });

      if (response.ok) {
        setSuccess(t('subscription_success'));
        setEmail('');
      } else {
        setError(t('subscription_failure'));
      }
    } catch (error) {
      setError(t('subscription_failure'));
    }
  };

  return (
    <div className="p-4">
      {/* <div className="text-white p-6 bg-black rounded-lg border-4 border-white shadow-md text-center max-w-lg mx-auto"> */}
      <div className="text-white p-6 bg-black rounded-lg shadow-md text-center max-w-lg mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold mb-6 mt-4">{t('register_message')}</h2>
        <form
          className="flex flex-col md:flex-row items-center text-black"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder={t('email_placeholder')}
            className="px-4 py-2 border rounded-lg md:rounded-l-lg w-full mb-4 md:mb-0 md:mr-2"
            required
          />
          <button
            type="submit"
            className=" bg-dark-red text-white px-4 py-2 rounded-lg md:rounded-r-lg w-full md:w-auto"
          >
            {t('register_button')}
          </button>
        </form>
        <div className="flex flex-col md:flex-row items-center justify-between mt-4">
          
        </div>
        {error && <p className="text-red-500 mt-4">{error}</p>}
        {success && <p className="text-green-500 mt-4">{success}</p>}
      </div>
    </div>
  );
}

export default EmailForm;
