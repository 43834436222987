import React from 'react';

function Footer() {
  return (
    <footer className="bg-black py-4 w-full border-t border-black">
      <div className="container mx-auto flex flex-col items-center text-center text-white">
        {/* Icone in alto */}
        <div className="h-8"></div>
        <div className="flex justify-center space-x-4 mb-4">
          {/* Icona email */}
          <a
            href="mailto:contact@santherya.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80"
          >
            <img
              src="images/email.png"
              alt="Email"
              className="h-6 w-6"
            />
          </a>

          {/* Icona Discord */}
          <a
            href="https://discord.gg/m9Em3UQps2"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80"
          >
            <img
              src="images/discord.png"
              alt="Discord"
              className="h-6 w-6"
            />
          </a>
        </div>
        <div className="h-8"></div>
        {/* Logo */}
        <img
          src="images/_0001_THE-PORTAL_LOGO_COMPLETO.png"
          alt="logo"
          className="h-16 mb-2"
        />
        <div className="h-8"></div>

        {/* Testo */}
        <p className="text-white font-bold">
          &copy; 2024 The Portal. All rights reserved.
        </p>
        <div className="h-8"></div>
      </div>
    </footer>
  );
}

export default Footer;
