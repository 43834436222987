import React from 'react';

function SplitContent({ 
  imageSide = 'left', 
  imageSrc, 
  bgImageSrc, 
  titleKey, 
  bodyKey, 
  height = '400px', 
  t 
}) {
  const isImageLeft = imageSide === 'left';

  return (
    <div className="flex flex-col md:flex-row w-full h-full" style={{ height }}>
      {/* Colonna Immagine */}
      <div className={`flex-1 ${isImageLeft ? '' : 'order-last'}`}>
        <img
          src={imageSrc}
          alt="Side"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Colonna Testo con Sfondo */}
      <div
        className="flex-1 flex flex-col justify-center items-center text-center text-white"
        style={{
          backgroundImage: `url(${bgImageSrc})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
    <div className='h-8'></div>
    <div className='px-8 py-4 md:px-12 md:py-8'>
        <h2 className="text-2xl font-bold mb-4">{t(titleKey)}</h2>
        <p className="text-base">{t(bodyKey)}</p>

    </div>
    <div className='h-8'></div>
      </div>
      
    </div>
  );
}

export default SplitContent;
