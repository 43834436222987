import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
         
          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Navbar />
        <Footer />
      </div>
    </Router>
  );
}

export default App;