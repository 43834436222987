import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const characters = [
  { key: "healer", image: "/images/healer.png" },
  { key: "mage", image: "/images/mage.png" },
  { key: "rogue", image: "/images/rogue.png" },
  { key: "tank", image: "/images/tank.png" },
  { key: "hunter", image: "/images/hunter.png" },
  { key: "warlock", image: "/images/warlock.png" },
  { key: "warrior", image: "/images/warrior.png" },
  { key: "engeneer", image: "/images/eng.png" },
];

function CharacterCarousel() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % characters.length);
  };

  const handlePrevious = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? characters.length - 1 : prevIndex - 1
    );
  };

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? -300 : 300,
      opacity: 0,
    }),
  };

  const getPreviousIndex = () =>
    currentIndex === 0 ? characters.length - 1 : currentIndex - 1;

  const getNextIndex = () => (currentIndex + 1) % characters.length;

  return (
    <div className="flex flex-col items-center text-white">
      <div className="md:h-8"></div>
      <h3 className="text-xl font-bold m-2 p-2">{t('background_section_title_3')}</h3>
      <p className="text-base text-left px-4 max-w-4xl">{t('background_section_text_3')}</p>
      <div className="md:h-12"></div>

      {/* Contenuto Immagine e Testo */}
      <div className="w-full md:w-3/4 mx-auto flex flex-col md:flex-row items-start md:space-x-6 space-y-0 md:space-y-0">
        {/* Colonna Immagine */}
        <div className="relative flex justify-center items-center w-full h-96">
  {/* Immagine precedente */}
  <motion.img
    src={characters[getPreviousIndex()].image}
    alt={t(`${characters[getPreviousIndex()].key}_title`)}
    className="absolute opacity-50 w-1/4 md:w-1/4 sm:w-1/3"
    style={{ left: '10%' }}
  />

  {/* Immagine corrente */}
  <AnimatePresence initial={false} custom={direction}>
    <motion.img
      key={currentIndex}
      src={characters[currentIndex].image}
      alt={t(`${characters[currentIndex].key}_title`)}
      className="absolute w-1/2 md:w-1/2 sm:w-2/3"
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    />
  </AnimatePresence>

  {/* Immagine successiva */}
  <motion.img
    src={characters[getNextIndex()].image}
    alt={t(`${characters[getNextIndex()].key}_title`)}
    className="absolute opacity-50 w-1/4 md:w-1/4 sm:w-1/3"
    style={{ right: '10%' }}
  />
</div>
<div className="md:hidden flex justify-center w-full px-4 mt-8">
        <button
          onClick={handlePrevious}
          className="text-2xl bg-gray-800 text-white rounded-full px-4 py-2 hover:bg-gray-700 mx-2 flex justify-center items-center"
        >
          <img
            src="images/BACK_CHARA_BUTTON.png"
            alt="Previous"
            className="w-16 h-16"
          />
        </button>
        <button
          onClick={handleNext}
          className="text-2xl bg-gray-800 text-white rounded-full px-4 py-2 hover:bg-gray-700 mx-2 flex justify-center items-center"
        >
          <img
            src="images/NEXT_CHARA_BUTTON.png"
            alt="Next"
            className="w-16 h-16"
          />
        </button>
      </div>
        {/* Colonna Testo */}
        <div className="w-full md:w-2/3 p-6 rounded-lg text-white">
        <div className='md:h-16'></div>
          <h2 className="text-3xl font-bold mb-4">
            {t(`${characters[currentIndex].key}_title`)}
          </h2>
          <p className="text-lg">{t(`${characters[currentIndex].key}_body`)}</p>
        </div>
      </div>

     {/* Immagini */}
    

      {/* Pulsanti Centrali */}
      <div className="hidden md:flex  justify-center w-full px-4 mt-8">
        <button
          onClick={handlePrevious}
          className="text-2xl bg-gray-800 text-white rounded-full px-4 py-2 hover:bg-gray-700 mx-2 flex justify-center items-center"
        >
          <img
            src="images/BACK_CHARA_BUTTON.png"
            alt="Previous"
            className="w-16 h-16"
          />
        </button>
        <button
          onClick={handleNext}
          className="text-2xl bg-gray-800 text-white rounded-full px-4 py-2 hover:bg-gray-700 mx-2 flex justify-center items-center"
        >
          <img
            src="images/NEXT_CHARA_BUTTON.png"
            alt="Next"
            className="w-16 h-16"
          />
        </button>
      </div>
    </div>
  );
}

export default CharacterCarousel;
