import React from 'react';

function FullWidthImage({ src, alt, height = 'auto', overlaySrc = null, overlayAlt = '' }) {
  return (
    <div
      className="relative w-full"
      style={{
        minHeight: height !== 'auto' ? height : undefined,
        height: 'auto',
      }}
    >
      {/* Immagine di Sfondo */}
      <img
        src={src}
        alt={alt}
        className="w-full object-cover"
        style={{
          minHeight: height !== 'auto' ? height : undefined,
          height: 'auto',
        }}
      />

      {/* Immagine Sovrapposta */}
      {overlaySrc && (
        <div className="absolute top-24 left-0 w-full flex justify-center">
          <img
            src={overlaySrc}
            alt={overlayAlt}
            className="w-2/3 md:w-1/2 object-contain"
          />
        </div>
      )}
    </div>
  );
}

export default FullWidthImage;
