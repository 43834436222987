import React from 'react';

function ImageSeparator({ src, alt }) {
  return (
    <div className="w-full">
      <img
        src={src}
        alt={alt}
        className="w-full object-cover"
      />
    </div>
  );
}

export default ImageSeparator;
