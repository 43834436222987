import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GB, IT } from 'country-flag-icons/react/3x2';

const languages = [
  { code: 'it-IT', name: 'Italiano', icon: IT },
  { code: 'en-EN', name: 'English', icon: GB },
];

function Navbar() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    // Sincronizza lo stato con la lingua iniziale di i18n
    setCurrentLang(i18n.language);
    console.log(i18n.language)
  }, [i18n.language]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang); // Aggiorna lo stato della lingua corrente
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    closeMenu();
  };

  return (
    <nav className="bg-dark-red p-4 w-full fixed top-0 border-1 border-black z-50">
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <img
            src="images/favicon.png"
            alt="logo"
            className="w-8"
            onClick={() => scrollToSection('top')}
          />
        </div>

        {/* Menu Desktop */}
        <ul className="hidden md:flex space-x-8 text-white font-bold">
          <li>
            <button
              onClick={() => scrollToSection('newsletter')}
              className="hover:underline"
            >
              {t('Newsletter')}
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('game')}
              className="hover:underline"
            >
              {t('The-game')}
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('story')}
              className="hover:underline"
            >
              {t('The-story')}
            </button>
          </li>
        </ul>

        {/* Icone Lingua */}
        <div className="flex items-center space-x-2">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              className={`p-1 rounded-md ${
                currentLang === lang.code ? 'bg-gray-300' : 'bg-transparent'
              }`}
            >
              <lang.icon
                className={`w-6 h-4 ${
                  currentLang === lang.code ? 'opacity-100' : 'opacity-50'
                }`}
              />
            </button>
          ))}
        </div>

        {/* Hamburger Menu Button */}
        <button
          className="md:hidden text-white text-2xl"
          onClick={toggleMenu}
        >
          ☰
        </button>
      </div>

      {/* Menu Mobile */}
      {menuOpen && (
        <div
          className="fixed top-0 left-0 w-3/4 h-full bg-dark-red p-4 text-white font-bold flex flex-col z-40"
        >
          <button className="self-end text-2xl" onClick={closeMenu}>
            ✕
          </button>
          <button
            onClick={() => scrollToSection('newsletter')}
            className="mt-4 hover:underline"
          >
            {t('Newsletter')}
          </button>
          <button
            onClick={() => scrollToSection('game')}
            className="mt-4 hover:underline"
          >
            {t('The-game')}
          </button>
          <button
            onClick={() => scrollToSection('story')}
            className="mt-4 hover:underline"
          >
            {t('The-story')}
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
